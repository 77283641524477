// import lumina_logo from '../images/lumina_spa_images/lumina_logo_for_card.png'
// import lumina_logo from '../images/lumina_spa_images/lumina_logo_for_card_SVG.svg'


// Cookies

import cookies_50gm_01 from '../images/Items_Images/Cookes_Images/cookies_50gm_01.jpg'
import cookies_50gm_02 from '../images/Items_Images/Cookes_Images/cookies_50gm_02.jpg'
import cookies_cake_1 from '../images/Items_Images/Cookes_Images/cookies_cake_1.jpg'
import cookies_cake_2 from '../images/Items_Images/Cookes_Images/cookies_cake_2.jpg'
import crunshey from '../images/Items_Images/Cookes_Images/crunshey.jpg'
import crunshey_02 from '../images/Items_Images/Cookes_Images/crunshey_02.jpg'
import mini_cookies from '../images/Items_Images/Cookes_Images/mini_cookies.jpg'
import mini_hot_cookies_01 from '../images/Items_Images/Cookes_Images/mini_hot_cookies_01.jpg'
import mini_hot_cookies_02 from '../images/Items_Images/Cookes_Images/mini_hot_cookies_02.jpg'

// Browines
import brownies_01 from '../images/Items_Images/Browines_Images/brownies_01.jpg'


import twitter_Icon from '../images/Footer_Images/twitter_icon_footer.png'
import instagram_Icon from '../images/Footer_Images/instagram_icon_footer.png'
import tiktok_Icon from '../images/Footer_Images/tiktok_icon_footer.png'
import whatsapp_Icon from '../images/Footer_Images/whatsapp_icon_footer.png'
import phone_Icon from '../images/Footer_Images/phone_icon_footer.png'



// Succuss Partners 
const AllOffersItems = [
    // Our Partners Offer
    // {
    //     id: "0",
    //     CategoryNameEN: "Our Partners",
    //     CategoryNameAR: "شركاؤنا",
    //     Cakes: [
    //         {
    //             id: '',
    //             img: lumina_logo,
    //             itemNameEn: "Up to 30% discount",
    //             itemNameAR: "خصم يصل إلى 30%",
    //             itemDescreptionEn: "Because every woman deserves care, we offer you a luxurious experience in your home",
    //             itemDescreptionAR: "لأن كل سيدة تستحق العناية، نقدم لكِ تجربة فاخرة في بيتك",
    //             // price: "45",
    //         }
    //     ]
    // },
]
const AllCakesItems = [

    // Cookies 
    {
        id: "01",
        CategoryNameEN: "Cookies",
        CategoryNameAR: "الكوكيز",
        Cakes: [
            {
                id: '01',
                img: mini_hot_cookies_01,
                itemNameEn: "Birthday Cake",
                itemNameAR: "ميني سخان كوكيز",
                itemDescreptionEn: "Available (4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "بوكس مكون من 6 حبات",
                price: "72",
            },
            {
                id: '02',
                img: cookies_50gm_01,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كوكيز 50 جرام",
                itemDescreptionEn: "Available (4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "بوكس مكون من 10 حبات",
                price: "70",
            },
            {
                id: '03',
                img: mini_cookies,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كب كوكيز",
                itemDescreptionEn: "Available (4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "بوكس مكون من 12 حبة",
                price: "48",
            },
            {
                id: '04',
                img: cookies_cake_1,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة كوكيز 4 انش",
                itemDescreptionEn: "Available (4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "إضافة 5 ريال للكتابة",
                price: "50",
            },
            {
                id: '05',
                img: cookies_cake_2,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كيكة كوكيز 8 انش",
                itemDescreptionEn: "Available (4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "اضافة 5 ريال للكتابة",
                price: "70",
            },
            {
                id: '06',
                img: crunshey,
                itemNameEn: "Birthday Cake",
                itemNameAR: "كرانشي",
                itemDescreptionEn: "Available (4 - 6 - 8 - 10 inches)",
                itemDescreptionAR: "بوكس مكون من 12 حبة",
                price: "48",
            },

        ]
    },
    // Brownies
    {
        id: "01",
        CategoryNameEN: "Brownies",
        CategoryNameAR: "براونيز",
        Cakes: [
            {
                id: '01',
                img: brownies_01,
                itemNameEn: "Birthday Cake",
                itemNameAR: "يكفي 3 - 4 أشخاص",
                itemDescreptionEn: "Enough for 3 - 4 people",
                itemDescreptionAR: "بوكس مكون من 8 قطع",
                price: "65",
            },
            {
                id: '02',
                img: brownies_01,
                itemNameEn: "Birthday Cake",
                itemNameAR: "يكفي 5 - 7 أشخاص",
                itemDescreptionEn: "Enough for 3 - 4 people",
                itemDescreptionAR: "بوكس مكون من 8 قطع",
                price: "100",
            }

        ]
    },

]

const SocialMediaIcons = [
    {
        icon: instagram_Icon,
        link: "https://www.instagram.com/reemcookies_sa"
    },
    {
        icon: twitter_Icon,
        link: "https://x.com/reemcookies_sa"
    },
    {
        icon: tiktok_Icon,
        link: "https://www.tiktok.com/@reemcookies_sa"
    },
    {
        icon: whatsapp_Icon,
        link: "https://wa.me/966570548680"
    },
    {
        icon: phone_Icon,
        link: "tel:+966570548680"
    },
]

export { SocialMediaIcons, AllOffersItems, AllCakesItems }